<template>
  <filter-base-menu
    ref="filterBaseMenu"
    v-model="selectedItems"
    :title="settings.title"
    :disabled="settings.disabled"
    :items="laborTypes"
    :total="total"
    :isLoading="isLoading"
    closable
    multiple
    @close="onClose"
    @show="onShow"
    @hide="onHide"
    itemValue="value"
    itemText="desc"
  >
    <template v-slot:item="{ item }">
      <div
        :key="item + '_laborTypeSelector'"
        class="d-flex align-center font-weight-medium"
        style="font-size: 14px"
      >
        {{ item.desc }}
      </div>
    </template>
  </filter-base-menu>
</template>

<script>
import { LaborTypeFilterSettings } from "../FilterSettings";
import enums from "../../../../../plugins/enums";
import FilterBaseMenu from "../FilterBaseMenu.vue";

export default {
  name: "labor-type-filter",
  components: { FilterBaseMenu },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => new LaborTypeFilterSettings(),
    },
  },
  computed: {
    laborTypes() {
      return enums.LABOR_TYPE_LIST;
    },
    total() {
      return this.laborTypes.length;
    },
  },
  data() {
    return {
      entities: [],
      selectedItems: [],
      isLoading: false,
      initiallyLoaded: false,
    };
  },
  methods: {
    onShow() {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    },
    onHide() {},
    onClose() {
      this.$emit("close");
    },
    clearSelection() {
      this.$refs.filterBaseMenu.clearSelection();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.selectedItems = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    selectedItems: {
      handler() {
        if (!this.isEqual(this.selectedItems, this.value)) {
          this.$emit("input", this.cloneDeep(this.selectedItems));
        }
      },
      deep: true,
    },
  },
};
</script>
