<template>
  <span v-if="type != null && templateTypeObj != null">
    <v-chip
      v-if="chip"
      class="template-type px-2"
      x-small
      :color="'secondary'"
      :text-color="'white'"
    >
      <i class="fas mr-2" :class="templateTypeObj.icon"></i>
      <span class="font-weight-medium">{{ templateTypeObj.text }}</span>
    </v-chip>
    <span v-else style="opacity: 0.87" class="d-flex align-center font-weight-medium fs-12px">
      <i class="fad mr-2" style="width: 12px" :class="templateTypeObj.icon"></i>
      <span>{{ templateTypeObj.text }}</span>
    </span>
  </span>
</template>

<script>
import enums from "../../../plugins/enums";
export default {
  name: "template-type",
  data() {
    return {
      templateTypes: enums.TemplateType,
      templateTypeObj: null,
    };
  },
  props: {
    type: {
      type: Number,
      default: null,
    },
    chip: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateDownloadTypeObj();
  },
  computed: {},
  methods: {
    updateDownloadTypeObj() {
      if (this.type != null)
        this.templateTypeObj = this.getEnumMember(this.templateTypes, this.type);
    },
  },
  watch: {
    type: {
      handler() {
        this.updateDownloadTypeObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
