<template>
  <v-menu
    transition="slide-y-transition"
    bottom
    nudge-bottom="32px"
    :content-class="isMenuOpen ? 'overflow-y-hidden white d-flex flex-column' : ''"
    :close-on-content-click="false"
    min-width="300px"
    max-width="300px"
    max-height="350px"
    v-model="isMenuOpen"
  >
    <template v-slot:activator="{ on: { click } }">
      <div :class="{ 'filter-active-item': closable }" class="d-flex align-center">
        <v-btn
          height="32"
          small
          class="text-capitalize"
          :class="closable ? 'pr-9' : 'pr-3'"
          :color="hasSelectedItem ? 'secondary' : 'white'"
          :disabled="disabled"
          @click="click"
        >
          <span class="filter-menu-title fs-13px">
            <i class="fad fa-filter me-2 fs-12px"></i>{{ title
            }}<span v-if="hasSelectedItem">: {{ firstSelectedItem[itemText] }}</span>
          </span>
          <v-chip
            x-small
            color="white"
            class="ml-1 font-weight-bold"
            text-color="secondary"
            v-if="hasMultipleSelectedItems"
            ><i class="fas fa-plus fs-7px" style="margin-right: 2px"></i
            >{{ selectedItems.length - 1 }}
          </v-chip>
          <!-- <i class="far fa-chevron-down ms-1" style="font-size: 12px"></i> -->
        </v-btn>
        <span class="filter-menu-closable-btn" v-if="closable" @click.stop="clearAndClose()">
          <i
            class="far fa-times"
            :class="[
              hasMultipleSelectedItems && hasMultipleSelectedItems[itemText]
                ? 'white--text'
                : 'grey--text lighten-2',
            ]"
          ></i>
        </span>
      </div>
    </template>
    <div class="d-flex align-center" style="position: relative">
      <v-text-field
        v-if="allowSearch"
        hide-details
        placeholder="Search"
        class="rounded-0"
        flat
        solo
        dense
        clearable
        :disabled="items.length == 0 && (searchTerm == null || searchTerm.trim == '')"
        v-model="searchTerm"
        @focus="onSearchFocus"
        @blur="onSearchBlur"
      >
        <template v-slot:prepend-inner>
          <i
            class="far fa-search mr-2"
            style="transition: 0.15s all ease-out"
            :class="isSearchInFocus ? 'secondary--text' : 'opacity-54'"
          ></i>
        </template>
      </v-text-field>
      <v-btn
        v-if="allowRefresh"
        @click="refresh"
        :disabled="isLoading"
        small
        icon
        color="teal"
        class="mx-2"
      >
        <i class="far fa-refresh fs-15px"></i>
      </v-btn>
      <v-progress-linear
        indeterminate
        color="secondary"
        striped
        :height="2"
        :active="isLoading"
        absolute
        bottom
        rounded
      ></v-progress-linear>
    </div>
    <v-divider></v-divider>
    <div
      width="300px"
      v-if="items.length == 0 && isLoading"
      class="d-flex flex-column align-center justify-center font-weight-medium pa-6"
    >
      <v-progress-circular indeterminate :size="25" :width="2" class="mb-2"></v-progress-circular>
      <span>Loading...</span>
    </div>
    <template v-else>
      <v-sheet
        width="300px"
        v-if="noMatch"
        class="d-flex flex-column align-center justify-center font-weight-medium pa-6"
      >
        <i class="fad fa-grin-beam-sweat mb-5" style="font-size: 48px"></i>
        <span>
          No matches found for
          <u>
            <b>{{ searchTerm }}</b>
          </u>
        </span>
      </v-sheet>
      <v-sheet
        width="300px"
        v-else-if="items.length == 0"
        class="d-flex flex-column align-center justify-center font-weight-medium pa-6"
      >
        <i class="fad fa-empty-set mb-5" style="font-size: 48px"></i>
        <div v-if="noItemsText == null || noItemsText.trim() == ''">No items available!</div>
        <div v-else v-html="noItemsText"></div>
      </v-sheet>
      <template v-else>
        <v-list class="overflow-y-auto py-2" :class="cssClass" width="300px" dense>
          <v-list-item-group
            color="info"
            active-class="filter-selected-item"
            :value="selectedItems"
            :multiple="multiple"
            ref="groupItems"
          >
            <v-list-item
              class="px-3"
              v-for="(item, i) in items"
              :value="item[itemValue]"
              :key="'itemList' + item[itemValue] + '_' + i"
              @change="selectedItemsChanged(item)"
              dense
            >
              <template v-slot:default="{ active }">
                <v-list-item-action v-if="multiple" class="v-list-item__action_baseMenu">
                  <v-checkbox :input-value="active" color="info" dense></v-checkbox>
                </v-list-item-action>
                <slot name="item" :item="item">
                  {{ item[itemText] }}
                </slot>
              </template>
            </v-list-item>
          </v-list-item-group>
          <v-btn
            @click="nextPage"
            v-if="showMore && allowPaging"
            :loading="isLoading"
            text
            block
            color="info"
            class="text-capitalize my-1"
            style="font-size: 14px"
          >
            Show More Entries
          </v-btn>
        </v-list>
        <div>
          <v-divider></v-divider>
          <div class="pl-1 pr-3 py-1 d-flex align-center">
            <v-btn
              text
              @click="clearSelection"
              v-if="hasSelectedItem"
              color="info"
              class="text-capitalize clear-selection-link"
              style="font-size: 14px"
            >
              Clear Selection
            </v-btn>
            <v-spacer></v-spacer>
            <div class="font-weight-medium grey--text text--darken-1 py-2" style="font-size: 14px">
              {{ `${items.length} of ${total}` }}
            </div>
          </div>
        </div>
      </template>
    </template>
  </v-menu>
</template>
<script>
export default {
  name: "filter-base-menu",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    selectedObjects: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: "id",
    },
    itemText: {
      type: String,
      default: "name",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    cssClass: {
      type: String,
      require: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    allowSearch: {
      type: Boolean,
      default: false,
    },
    allowPaging: {
      type: Boolean,
      default: false,
    },
    allowRefresh: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    search: {
      type: String,
      require: null,
    },
    noItemsText: {
      type: String,
      require: null,
    },
  },
  computed: {
    showMore() {
      return this.items.length < this.total;
    },
    noMatch() {
      return this.searchTerm && !this.items.length && !this.isLoading;
    },
    hasMultipleSelectedItems() {
      return this.multiple && this.savedSelections.length > 1;
    },
    hasSelectedItem() {
      if (this.multiple) return this.savedSelections.length > 0;
      else return this.savedSelections != null;
    },
    firstSelectedItem() {
      if (!this.hasSelectedItem) return null;
      if (this.multiple)
        return this.savedSelections.find(
          (item) => item[this.itemValue] == this.savedSelections[0][this.itemValue]
        );
      else
        return this.savedSelections.find(
          (item) => item[this.itemValue] == this.savedSelections[this.itemValue]
        );
    },
  },
  data() {
    return {
      selectedItems: null,
      isMenuOpen: false,
      searchTerm: null,
      isSearchInFocus: false,
      savedSelections: [],
    };
  },
  created() {
    this.selectedItems = this.multiple ? [] : null;
  },
  methods: {
    nextPage() {
      this.$emit("update:page", this.page + 1);
    },
    setsMenuOpen(state) {
      this.isMenuOpen = state;
    },
    onSearchFocus() {
      this.isSearchInFocus = true;
    },
    onSearchBlur() {
      this.isSearchInFocus = false;
    },
    clearAndClose() {
      this.clearSelection();
      this.$nextTick(() => {
        this.setsMenuOpen(false);
        this.$emit("close");
      });
    },
    refresh() {
      this.$emit("refresh");
    },
    clearSelection() {
      this.savedSelections = this.multiple ? [] : null;
      /////////////////
      var _items = this.multiple ? [] : null;
      if (this.multiple) _items = this.savedSelections.map((item) => item[this.itemValue]);
      else _items = this.savedSelections.map((item) => item[this.itemValue])[0];

      this.$emit("input", this.cloneDeep(_items));
      this.selectedItems = this.savedSelections.map((item) => item[this.itemValue]);
    },
    selectedItemsChanged(targetItem) {
      this.$log("selectedItemsChanged", this.cloneDeep(targetItem));
      var itemIndex = this.savedSelections.findIndex(
        (e) => e[this.itemValue] == targetItem[this.itemValue]
      );
      if (itemIndex == -1) this.savedSelections.push(targetItem);
      else this.savedSelections.splice(itemIndex, 1);
      /////////////////
      var _items = this.multiple ? [] : null;
      if (this.multiple) _items = this.savedSelections.map((item) => item[this.itemValue]);
      else _items = this.savedSelections.map((item) => item[this.itemValue])[0];

      this.$emit("input", this.cloneDeep(_items));
      this.selectedItems = this.savedSelections.map((item) => item[this.itemValue]);
      this.$emit("update:selected-objects", this.cloneDeep(this.savedSelections));
      // this.$log("selectedItemsChanged selectedItems", this.cloneDeep(this.selectedItems));
    },
  },
  watch: {
    isMenuOpen: {
      handler(val) {
        if (this.isMenuOpen) this.$emit("show");
        else this.$emit("hide");
      },
      immediate: true,
    },
    search: {
      handler(val) {
        if (!this.isEqual(this.searchTerm, this.search)) {
          this.searchTerm = this.search;
        }
      },
    },
    searchTerm: {
      handler(val) {
        if (!this.isEqual(this.searchTerm, this.search)) {
          this.$emit("update:search", this.searchTerm);
        }
      },
    },
    items: {
      handler() {
        this.$nextTick(() => {
          this.selectedItems = this.savedSelections.map((item) => item[this.itemValue]);
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.filter-selected-item {
  position: relative;

  &::after {
    content: "";
    width: 2px;
    height: 28px;
    min-height: unset !important;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $info-base;
    border-radius: 0 0.25rem 0.25rem 0;
  }
}
.filter-active-item {
  position: relative;

  .v-btn {
    padding-right: 36px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .filter-menu-closable-btn {
    position: absolute;
    right: 4px;
    padding: 0.35rem;
    width: 24px;
    height: 24px;
    top: calc(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    transition: all 0.15s ease-out;
    line-height: 0;
    border-radius: 0.25rem;
    user-select: none;

    i {
      transition: all 0.3s ease-out;
      line-height: 0;
    }

    &:hover {
      background: rgba($shades-black, 0.08);
      cursor: pointer;
    }

    &:active {
      cursor: pointer;
      background: rgba($shades-black, 0.87);

      i {
        color: #fff !important;
      }
    }
  }
}

.v-list-item__action_baseMenu {
  margin-left: 0px !important;
  margin-right: 1rem !important;
}
</style>
